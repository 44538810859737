import '../bootstrap';

jQuery(function ($) {
    let sigEl = $('#card_signature');
    let textEl = $('#card_text');
    let imageEl = $('#image');
    let thanksEl = $('#thanks_btn');

    if (textEl.length === 0) {
        return;
    }

    function updateImage() {
        let jqxhr = $.post('/api/update-image', {signature: sigEl.val(), text: textEl.val()}, 'json')
            .done(function (response) {
                imageEl.attr('src', 'data:image/png;base64,' + response.img);
            })
            .fail(function () {
                alert("Wystąpił błąd.");
            })
            .always(function() {
                thanksEl.prop('disabled', false);
                thanksEl.removeClass('disabled');
            })

    }

    function debounce(fn, delay) {
        let args = [].slice.call(arguments);
        let context = this;
        clearTimeout(textEl.data('timer'));

        textEl.data('timer', setTimeout(function () {
            fn.apply(context, args);
        }, delay));
    }

    textEl.bind('input propertychange', function () {
        let maxCharacters = 500;
        let obj = $(this);
        let val = obj.val().replace(/(?:\r\n|\r|\n)/g, '');

        if (val.length > maxCharacters) {
            val = val.slice(0, maxCharacters);
        }

        obj.val(val);

        debounce(updateImage, 1500);
    });


    sigEl.bind('input propertychange', function () {

        thanksEl.prop('disabled', true);
        thanksEl.addClass('disabled');

        let maxCharacters = 50;
        let obj = $(this);
        let val = obj.val().replace(/(?:\r\n|\r|\n)/g, '');

        if (val.length > maxCharacters) {
            val = val.slice(0, maxCharacters);
        }

        obj.val(val);

        debounce(updateImage, 1500);
    });

    updateImage();
})
